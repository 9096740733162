const messages = {
  bg: {
    orders: {
      actions: {
        addNewOrder: 'Добавете поръчка',
        all: 'Всички поръчки',
      },
      listPage: {
        title: 'Поръчки',
        table: {
          filter: {
            number: 'Номер',
          },
          columns: {
            contact: 'Контакт',
            dateOfOrder: 'Дата на поръчка',
            number: 'Номер',
            offer: 'Оферта',
            opportunity: 'Специално предложение',
            owner: 'Купувач',
            state: 'състояние',
            total: 'Обща сума',
            totalUserCurrency: 'Общо в {currency}',
          },
        },
      },
      order: {
        newEntity: 'Нова поръчка',
        contact: {
          label: 'Контакт',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Общо начисления',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Валута',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Дата на поръчка',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Номер',
          helperText: 'Оставете празно за автоматично генериране на номера.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Оферта',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Специално предложение',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Купувач',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Търговска надбавка',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Продукт',
          discount: {
            label: 'Отстъпка',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Общо начисления',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Размер на пакета',
          },
          listPrice: {
            label: 'Ценоразпис',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Продажна цена',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Продукт',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Количество',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Търговска надбавка',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Общо за артикула',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Мерна единица',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Обща сума',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, = 0 {няма артикули} one {1 артикул} few {{number} артикула} other {{number} артикула}}',
      },
      myOrdersScoringBlog: {
        title: 'Моите поръчки',
      },
      ordersList: 'Поръчки',
    },
  },
};

export default messages;
