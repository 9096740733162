const messages = {
  bg: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Кошница',
        },
      },
      productDetail: {
        parameters: {
          title: 'Параметри',
        },
      },
      productAvailability: {
        format: {
          available: 'В наличност',
          moreThanX: 'Повече от {min} броя на склад',
          exactCount: '{value} бройки на склад',
          notAvailable: 'Няма наличност',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} още',
      },
      relatedProducts: {
        title: 'Свързани продукти',
      },
      productAccessories: {
        title: 'Аксесоари',
      },
      productDetailImageGallery: {
        noImage: 'Няма снимка',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Кога ще получа стоката?',
      },
    },
  },
};

export default messages;
