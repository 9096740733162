import PropTypes from 'prop-types';
// import shoppingListMessages from './react-shopping-lists/sr';
import messages from './bg';
import comOrdersMessages from './react-com-orders/bg';
import comMessages from './react-com/bg';
import formsMessages from './react-forms/bg';
import compMessages from './react-mui-components/bg';
// import fieldMessages from './react-mui-fields/sr';
// import pagesMessages from './react-mui-pages/sr';
import tablesMessages from './react-mui-tables/bg';
import shoppingCartMessages from './react-shopping-cart/bg';

// const rename = ({ sr }) => ({ bg: sr });

function MessagesBg({ children }) {
  return children(
    [
      compMessages,
      // fieldMessages,
      tablesMessages,
      formsMessages,
      // pagesMessages,
      comMessages,
      // shoppingListMessages,
      shoppingCartMessages,
      comOrdersMessages,
      // invoicesMessages,
      messages,
    ], // .map(rename),
  );
}

MessagesBg.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesBg;
