const messages = {
  bg: {
    shoppingCart: {
      actions: {
        addToCart: 'Добави в кошницата',
        buy: 'Купи',
        order: 'Поръчка',
        remove: 'Премахване',
      },
      cart: {
        total: {
          label: 'Обща сума',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Общо на редове',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Общо за транспорт',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Общо за плащане',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Общо данъци',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Нулиране на количката',
      },
      messages: {
        confirmResetCart: 'Наистина ли искате да изтриете съдържанието на количката?',
        giftClaim: 'Можете да получите {quantity} парчета {productName} като подарък.',
        cartManipulations: {
          allRemoved: 'Всички артикули в количката са изтрити.',
          itemAdded: 'Добавяне в количката {number, plural, one {артикул} other {добавени артикули}}.',
          itemRemoved: 'От количката {number, plural, one {елемент премахнат} other {елементи премахнати}}.',
          quantityChaged: 'Закупеното количество е променено.',
        },
      },
      components: {
        appBartActionCart: 'Кошница',
        quantityField: {
          states: {
            quantityRounded: 'Количеството беше закръглено до най-близкия размер на опаковката.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Кошницата ви е празна',
        },
        cartAfterAddModal: {
          continueShopping: 'Продължи пазаруването',
          goToCart: 'Отиди в количката',
          title: 'Продукти добавени в количката',
          pcs: 'бр',
        },
      },
    },
  },
};

export default messages;
