import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { pickHTMLProps } from 'pick-react-known-prop';
import MasterCardIcon from '@sprinx/react-com/MasterCardIcon';
import VisaLogoIcon from '@sprinx/react-com/VisaLogoIcon';
import VisaElectronLogoIcon from '@sprinx/react-com/VisaElectronLogoIcon';
import MaestroLogoIcon from '@sprinx/react-com/MaestroLogoIcon';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    image: {
      height: '100%',
      maxHeight: 90,
      maxWidth: '100%',
    },
    imageExtended: {
      maxHeight: '100%',
      maxWidth: 120,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 100,
        height: 100,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        '& svg': {
          width: '100%',
        },
      },
    },
  }),
  { name: 'FooterPartners' },
);

const partners = [
  {
    svg: <VisaLogoIcon height={90} />,
    name: 'visa',
  },
  {
    svg: <VisaElectronLogoIcon height={90} />,
    name: 'visa electron',
  },
  {
    svg: <MasterCardIcon height={90} />,
    name: 'mastercard',
  },
  {
    svg: <MaestroLogoIcon height={90} />,
    name: 'maestro',
  },
  {
    image: '/Verified_by_Visa_logo.png',
    name: 'verified by visa',
    class: 'extended',
  },
  {
    image: '/mastercard-securecode-logo-E93D5A1D1A-seeklogo.com.png',
    name: 'mastercard securecode',
    class: 'extended',
  },
];

function FooterPartners({ classes: pClasses, className, ...rest }) {
  const classes = useStyles({ classes: pClasses });

  return (
    <div {...pickHTMLProps(rest)} className={clsx(classes.root, className)}>
      {partners.map((i, index) => (
        <div className={classes.container} key={index.toString()}>
          {i.svg ? (
            <span>{i.svg}</span>
          ) : (
            <img src={i.image} alt={i.name} className={i.class ? classes.imageExtended : classes.image} />
          )}
        </div>
      ))}
    </div>
  );
}

FooterPartners.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    image: PropTypes.string,
    imageExtended: PropTypes.string,
    root: PropTypes.string,
  }),
  className: PropTypes.string,
};

FooterPartners.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default FooterPartners;
