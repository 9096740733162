const messages = {
  bg: {
    reactMuiTables: {
      selected: 'избрани',
      filterhide: 'Скриване на филтъра',
      filtershow: 'Покажи филтър',
      refresh: 'Възстанови',
      filter: {
        actions: {
          search: 'Търсене',
          resetFilter: 'Нулиране на филтъра',
          addCondition: 'Добавете',
        },
      },
      standardOperators: {
        equals: 'равен',
        contains: 'съдържа',
        between: {
          label: 'между',
          from: 'от',
          to: 'до',
        },
        oneOf: 'един от',
      },
      labelRowsPerPage: 'Редове на страница:',
    },
  },
};

export default messages;
