const messages = {
  bg: {
    sprinxFormsValidation: {
      required: 'Задължително поле!',
      string: 'Въведете текст!',
      stringEmpty: 'Попълнете полето!',
      stringMin: 'Дължината трябва да е по-голяма или равна на  {expected}  знака!',
      stringMax: 'Дължината трябва да бъде по-малка или равна на {expected} знака!',
      stringLength: 'Дължината трябва да бъде {expected} знака!',
      stringPattern: 'Грешен формат!',
      stringContains: 'Трябва да съдържа текста „{expected}“!',
      stringEnum: 'Не отговаря на нито една от разрешените стойности!',
      stringNumeric: 'Въведете число!',
      number: 'Въведете число!',
      numberMin: 'Трябва да е по-голямо или равно на {expected}!',
      numberMax: 'Трябва да е по-малко или равно на {expected}!',
      numberEqual: 'Трябва да е равно на {expected}!',
      numberNotEqual: 'Не може да бъде равно на {expected}!',
      numberInteger: 'Въведете цяло число',
      numberPositive: 'Въведете положително число!',
      numberNegative: 'Въведете отрицателно число!',
      array: 'Трябва да е списък!',
      arrayEmpty: 'Nesmí být prázdný seznam!',
      arrayMin:
        'Необходими са поне {number, plural, one {1 артикул} few {{number} артикула} other {{number} артикула}}!',
      arrayMax:
        'Не може да съдържа повече от {expected, plural, one {1 артикул} few {{number} артикула} other {{number} артикула}}!',
      arrayLength:
        'Трябва да съдържа точно {expected, plural, one {1 артикул} few {{number} елемента} other {{number} артикула}}!',
      arrayContains: 'Трябва да съдържа „{expected}“!',
      arrayEnum: 'Стойността „{expected}“ не съответства на нито една от разрешените стойности!',
      boolean: 'Трябва да е „да“ или „не“!',
      function: 'Въведете функция!',
      date: 'Въведете дата',
      dateMin: 'Трябва да е по-голямо или равно на {expected}!',
      dateMax: 'Трябва да е по-малко или равно на {expected}!',
      forbidden: 'Полето е забранено!',
      email: 'Невалиден e-mail!',
      url: 'Невалиден URL!',
      object: 'Въведете тип Object!',
      arrayReducedEquals: 'Общата стойност не съвпада с {expected}!',
      phoneNumber: 'Въведете валиден международен телефонен номер в „+ XXX XXX XXX XXX“!',
      dateGt: 'Датата трябва да е по-голяма от  {expected}!',
      dateGte: 'Датата трябва да е по-голяма или равна на {expected}!',
      dateLt: 'Датата трябва да е по-малка от {expected}!',
      dateLte: 'Датата трябва да е по-малка или равна на {expected}!',
    },
  },
};

export default messages;
