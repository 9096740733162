const messages = {
  bg: {
    app: {
      about: 'Относно приложението',
      actions: {
        add: 'Добавете',
        addNamed: 'Добавете {name}',
        remove: 'Изтрит',
        removeNamed: 'Изтрит {name}',
        removeConfirmation: 'Сигурни ли сте, че желаете да изтриете?',
        more: 'Още',
        less: 'По-малко',
        download: 'Изтегли',
        save: 'Запази',
        cancel: 'Отмяна',
        close: 'Затвори',
        moreFormFields: 'Още полета',
        export: 'Експортиране',
        edit: 'Редактиране',
        createdSuccessMessage: 'Успешно създадено.',
        savedSuccessMessage: 'Успешно запазено.',
        deletedSuccessMessage: 'Успешно изтрито',
        detail: 'Детайлно',
        next: 'Следващ',
        back: 'Назад',
        start: 'Започнете',
        whatNext: 'Какво следва...',
        confirm: 'Потвърждение',
        confirmRemove: 'Сигурни ли сте, че желаете да изтриете?',
        search: 'Търсене',
        userProfile: 'Потребителски профил',
        tenantProfile: '',
        imports: 'Импортиране',
        fileImports: 'Импортиране на файлове',
        settings: 'Настройки',
        logout: 'Излез от профила си',
        login: 'Вход',
        filterList: 'Списък с филтри',
        excel: 'Excel',
        refresh: 'Възстанови',
        clear: 'Изтрии',
        agree: 'Съгласен съм',
        disagree: 'Не съм съгласен',
        makeCopy: 'Копирай',
        fullscreen: 'Цял екран',
        fullscreenClose: 'Затворете режима на цял екран',
        copy: 'Kopírovat',
      },
      messages: {
        confirmRemove: 'Наистина ли искате да изтриете този елемент?',
        errorOccurred: 'Възникна грешка',
        createdSuccess: 'Успешно създаден',
        savedSuccess: 'Успешно записан',
        deletedSuccess: 'Успешно изтрит',
        uploadSuccess: 'Файлът е качен успешно.',
        uploadError: 'Грешка при качването на файла.',
        uploadInProgress: 'Качване...',
        uploadedFiles:
          '{число, множествено число, един {1 soubor byl uploadnutý} няколко {{number} качени файла} други {{number} качени файлове}}',
      },
      error: 'Грешка!',
      validations: {
        isRequired: 'Полето {field} е задължително.',
        minLengthIsRequired:
          'Необходими са поне {число, множествено число, един {1 артикул}, няколко {{number} артикула} други {{number} артикула}}.',
        minValue: 'Минималната допустимата стойност е {value}.',
        maxValue: 'Максимално допустимата стойност е {value}.',
        passwordMatchError: 'Паролите не съвпадат',
        invalidEmail: 'Грешен имейл.',
        invalidPhoneNumber:
          'Невалиден телефонен номер. Въведете телефонния номер в международен формат "+ X XXX XXX XXX".',
        notValidNumber: 'Невалиден номер',
        invalidUrl: 'Невалиден URL адрес.',
        positive: 'Числото трябва да е положително.',
        negative: 'Числото трябва да е отрицателно.',
      },
      login: {
        title: '',
        username: 'Потребителско име',
        password: 'Парола',
        loginButton: 'Вход',
        error: 'Неуспешно влизане. Потребителско име или парола не са намерени',
        pleaseRelogin: 'Изглежда не сте влезли. Моля, влезте отново.',
      },
      days: '{брой, множествено число, един {1 den} няколко {{formattedCount} дни} други {{formattedCount} дни}}',
      overdue:
        'забавяне {брой, множествено число, един {1 den} няколко {{formattedCount} дни} други {{formattedCount} дни}}',
      newItem: 'Нови продукти',
      anonymizing: {
        confirm: {
          title: '',
          content: '',
        },
        button: '',
      },
      appMenu: {
        discover: '',
      },
      missingType: '',
      missingTypeForEntityType: '',
      emptyScoringBlog: '',
    },
    components: {
      buttons: {
        showOnMap: '',
      },
      filters: {
        filterButtonSubmit: 'Търсене',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Добавете',
        resetFilter: 'Нулиране на филтъра',
        operators: {
          contains: {
            0: 'obsahuje',
            _: 'obsahuje',
          },
          containsexact: {
            0: 'съдържа точно',
            _: 'съдържа точно',
          },
          notcontains: {
            0: 'не съдържа',
            _: 'не съдържа',
          },
          betweennumber: {
            0: 'от',
            1: 'до',
            _: 'между',
          },
          betweendate: {
            0: 'от',
            1: 'до',
            _: 'между',
          },
          equal: {
            0: 'е равно',
            _: 'е равно',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Общо елементи: {number}',
        nextPage: 'Заредете {number} елемента',
        emptyMessage: 'Артикулите не са намерени.',
      },
      selects: {
        emptyOption: 'Нито един',
        emptyMessage: 'Артикулите не са намерени.',
      },
      tables: {
        labelRowsPerPage: 'Редове на страницата',
        sortBy: 'Сортиране по {field}',
        recordsChanged: 'Някои записи са добавени или променени.',
      },
      textField: {
        errorMsgWrapper: 'Грешка: {msg}',
      },
      dateField: {
        invalidDateFormat: 'Неправилен формат на датата.',
        invalidDate: 'Неизвестен',
        maxDateMessage: 'Данните не може да бъдат по-големи от максимума',
        minDateMessage: 'Данните не може да бъдат по-малки от минимума',
        today: 'Dnes',
      },
      detailNavigation: {
        scoringblog: 'Scoring Blog',
        card: 'Карта',
        relations: 'Сесия',
      },
      manipulationInfo: {
        created: 'Създаден',
        updated: 'Актуализиран',
        removed: 'Изтрит',
      },
    },
  },
};

export default messages;
