const MessagesBg = {
  bg: {
    parameters: {
      yes: 'да',
    },
    com: {
      phase: {
        label: 'Обозначаване',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Модел',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Производител',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Номер на частта',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Гаранция',
          months: 'месеци',
        },
      },
      totalAmount: {
        label: 'Обща сума',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ {number} следващ',
    },
    orders: {
      actions: {
        all: 'Всички поръчки',
      },
      myOrdersScoringBlog: {
        title: 'Моите поръчки',
      },
      ordersList: 'Поръчки',
      phase: {
        1: 'Приет',
        10: 'Обработва се',
        20: 'Изчаква се плащане',
        30: 'Платено',
        98: 'На изчакване',
        99: 'Отказано',
        '01-new': 'Прието',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Номер на пакета',
            phase: 'Състояние',
            paid: 'Платено',
            total: 'без ДДС',
            totalWithTax: 'Общо',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Кошница',
        buyButton: 'Изпратена поръчка',
        emptyBasket: 'Вашата количка е празна',
        buyThere: 'Вече пазарувах тук',
        login: 'Искам да се запиша',
        notFill: 'Данните за доставка не са попълнени',
        fill: 'Попълнете информацията',
        change: 'Промяна на данните',
        popupButton: 'Покажи на картата',
        payment: {
          title: 'Метод на плащане',
          free: 'Безплатно',
          changePaymentBtn: 'ПРОМЕНЯ НАЧИН НА ПЛАЩАНЕ',
        },
        shipping: {
          title: 'ДОСТАВКА',
          free: 'Безплатно',
          changeShippingBtn: 'ПРОМЕНЯТЕ НАЧИНА НА ДОСТАВКА',
          popup: {
            title: 'Информация',
            text: 'Доставка до дома директно с куриерска {service}',
          },
        },
      },
      actions: {
        all: 'Всички',
      },
      myBasketsScoringBlog: {
        title: 'За съхранение',
      },
      cartSummaryParameters: {
        caption: 'Код на продукта',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Описание',
      parameters: 'Параметри',
      package: 'Съдържание на пакета',
      fotoAndVideo: 'Снимки и видео',
      alternatives: 'Подобен',
      alternativesText: 'За съжаление в момента нямаме заместител на продукта, който сте избрали.',
      questions: 'Въпроси',
      productDescriptionNotAvailable: 'Описанието на продукта не е налично',
    },
    newsletter: {
      phone: '+381 62 8132264',
      subscribe: {
        title: 'Не пропускайте специалните предложения',
        inputText: 'Вашата електронна поща',
        button: 'Регистрирай се',
      },
    },
    globalNavigation: {
      home: 'Начална страница',
      shopping: 'Купи',
      orders: 'Поръчки',
      shoppingLists: 'Списъци за пазаруване',
      articles: 'Новини',
    },
    homePageWhy: {
      0: {
        title: 'Купете безопасно',
        content:
          'Ние сме пълноправен член на Асоциацията за електронна търговия и гарантираме безопасно онлайн пазаруване.',
      },
      1: {
        title: 'Потребителска поддръжка',
        content: 'Удовлетворението на клиентите е важно за нас, така че ние винаги сме до вас.',
      },
      2: {
        title: 'Наличност на стоки',
        content: 'Разполагаме с 98% от цялата гама на склад, готови за доставка веднага.',
      },
      3: {
        title: 'Информация за доставка',
        content: 'Поръчки направени до 13:30 часа се предават на куриер в същия ден.',
      },
      title: 'ЗАЩО ДА КУПИТЕ ОТ НАС?',
    },
    startPage: {
      title: 'Начална страница',
      gotoShopping: 'Купи',
    },
    shoppingCart: {
      buyBtn: 'ДОБАВИ В КОШНИЦАТА',
      alternatives: 'Подобен',
      shipping: {
        changePoint: 'Редактиране',
      },
      agreements: {
        termsRequired: 'Трябва да се съгласите с условията.',
      },
      validation: {
        someProductNotAvailable: 'Някои продукти не са на склад в достатъчни количества.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Код на продукта:',
    },
    productListPage: {
      title: 'Каталог',
      orders: {
        recommended: 'Препоръчва се',
        name: 'Název',
        priceAsc: 'Възходящо по цена',
        priceDesc: 'Низходящо по цена',
      },
    },
    orderStatuses: {
      storno: 'Отмяна',
      synchronized: 'Изпратено',
      waiting: 'Изчаква се синхронизация',
    },
    orderPreview: {
      items: 'Предмети',
    },
    cartRegisterForm: {
      title: 'Формуляр за регистрация',
    },
    cartPayment: {
      title: 'Плащане',
    },
    cartPreSummaryPage: {
      title: 'Обобщение на количката',
      actions: {
        nextStep: 'Съгласен съм и продължавам',
      },
    },
    cartCompanyForm: {
      description: 'Попълнете, ако купувате стоки за компанията.',
    },
    cartDeliveryForm: {
      description: 'Попълнете, ако адресът за доставка се различава от адреса за фактуриране.',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Кога ще получа стоката?',
    },
    productDetailStockInfo: {
      available: 'Разполагаме с продукта в нашия разпределителен склад.',
      external: 'Продуктът е на път за нашия склад',
      notAvailable: 'Стоката временно не е налична',
      labels: {
        free: 'БЕЗПЛАТНА ДОСТАВКА',
        showroom: 'ИЗЛОЖБЕНА СТОКА',
      },
    },
    productGifts: {
      btnName: 'ПОДАРЪК',
      giftValue: 'В стойността',
    },
    homepage: {
      productOfMonth: 'Продукти на месеца',
      catalogFavorite: 'Любими категории',
      hotTips: 'ЕКСКЛУЗИВНИ ОФЕРТИ',
      recommended: 'Препоръчва се',
    },
    productQuestion: {
      firstName: 'Име',
      lastName: 'Фамилия',
      email: 'Еелектронна поща',
      subject: 'Предмет',
      question: 'Запитване',
      gdpr: 'С изпращането на запитване потвърждавам обработването на лични данни, за да отговорят на запитването.',
      gdprLink: 'връзка към GDPR',
      button: 'Изпратете въпрос',
      subjectContent: 'Въпрос за артикула: {product}, код: {sku}',
      title: 'Трябва да се съгласите с обработването на лични данни.',
      success: 'Изпратено успешно',
    },
    lastVisitedProducts: 'Наскоро посетени продукти',
    productAlternativeProducts: 'Алтернативи',
    productAccessoryProducts: 'Екстри',
    productReplacementProducts: 'Приложение',
    productAccessories: 'Допълнение',
    productReplacements: 'Замяна',
    appProductDetailTabs: {
      description: 'Описание',
      parameters: 'Параметри',
      package: 'Съдържание на пакета',
      query: 'Запитване',
      media: 'Снимки и видео',
      alternatives: 'Алтернативи',
      accessories: 'Екстри',
      replacements: 'Приложение',
      form: {
        enum: '!!!! ENUM СТОЙНОСТ',
      },
    },
    cartSummaryPage: {
      title: 'Обобщение преди поръчка',
      actions: {
        continueShopping: 'Продължи пазаруването',
        saveOrder: 'Създайте поръчка',
      },
      expectedDeliveryDate: {
        label: 'Необходима информация за доставка',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Забележка за доставчиците',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Вход за плащане',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Потребителски профил',
      content: 'Резултат',
      contactInfo: {
        newsletter: {
          title: 'Изпращайте известия по имейл',
          content: 'Искам да получавам известия от сайта Digiexpert.bg',
        },
        title: 'Информация за връзка',
      },
      orders: {
        title: 'История на поръчките',
      },
      invoices: {
        title: 'Моите фактури',
      },
      myOrders: 'Моите поръчки',
      myInvoices: 'Моите фактури',
      logout: 'Разгледайте',
      login: 'Приложи',
      favorites: {
        title: 'Любими',
      },
      changePassword: {
        title: 'Променете паролата',
        fields: {
          login: 'Влез (ne može se promeniti)',
          originalPassword: 'Парола',
          newPassword: 'Нова парола',
          confirmNewPassword: 'Потвърди парола ',
        },
        button: 'Запазите промените',
      },
    },
    orderSavedThankPage: {
      title: 'Благодаря ви за вашата поръчка {orderNumber}',
      thankMessage: {
        template:
          'Проверете имейл адреса си, за да потвърдите поръчката си. Запазете имейла за ваша собствена употреба. По-долу ще намерите информация за вашата поръчка',
        phoneNumber: '',
        email: 'info@digiexpert.bg',
      },
      order: 'Номер на поръчка:',
      payment: 'Плащане на:',
      cancelled: 'Отказано',
      accepted: 'Прието',
      pending: 'Очаква се',
      actions: {
        back: 'Обратно към началната страница',
        detail: 'Подробности за поръчката',
        continue: 'Продължи пазаруването',
      },
      decline: 'Отказано',
    },
    shoppingLists: {
      title: 'Списъци за пазаруване',
      actions: {
        saveAsShoppingList: 'Запазване като списък за пазаруване',
        importShoppingListToCart: 'Добави',
      },
    },
    searchResultPage: {
      search: 'Търсене:',
      results: 'Резултати от търсенето',
    },
    homePageArticles: {
      title: 'Новини',
      button: 'Вижте всички статии',
    },
    loginPage: {
      title: 'Влизам',
      forgotPassword: 'Нулиране на паролата',
      email: 'Електронна пощ',
      sendEmail: 'Изпратете имейл',
      info: 'Ще получите имейл с линк за промяна на вашата парола',
      password: 'Парола',
      notSame: 'не е същото',
      newPassword: 'Нова парола',
      confirmPassword: 'Потвърди парола',
      changePassword: 'Промяна на паролата',
    },
    productPrice: {
      withoutTax: 'без ДДС',
    },
    productDetailPage: {
      availability: {
        dateRange: [
          '{minDays, select,',
          '      2 {Поръчайте още {today} и след {minDays} - {maxDays} дни ще ви бъдат доставени.}',
          '      3 {Поръчайте още {today} и след {minDays} - {maxDays} дни ще ви бъдат доставени.}',
          '  other {Поръчайте още {today}. Ще ви бъде доставен след {minDays} - {maxDays} дни.}',
          '}',
        ],
        today: 'Поръчайте днес до 13:30 часа и го изпращаме към вас на същият ден.',
        todayWord: 'днес',
        tomorrow: 'Поръчайте {today}, утре веднага Ви го изпращаме​.',
        dayOfWeek: {
          1: 'понеделник',
          2: 'вторник',
          3: 'сряда',
          4: 'четвъртък',
          5: 'петък',
          6: 'събота',
          0: 'неделя',
          message: 'Поръчайте още {today}, поръчката ще ви бъде изпратена на {day}.',
        },
        workingDay: 'Поръчайте до 13:30 и изпращаме',
        afterWorkingDay: 'Поръчайте днес и изпращаме',
      },
      allDescription: 'ПЪЛНО ОПИСАНИЕ НА ПРОДУКТА',
      demoProduct: {
        text: 'КУПЕТЕ ПО-ЕВТИНО за:',
      },
      productNote: {
        title: 'КУПЕТЕ ПО-ЕВТИНО за: 2 150,00 €',
        note: 'Забележка 2 - Допълнително описание на състоянието или подобна информация за продукта, защо е намален и какво има върху стоките...',
      },
      adornments: {
        notForSale: 'Не се продава отделно',
        usedProduct: 'Базар',
        freeGift: 'Безплатен подарък',
        freeShipping: 'Безплатна доставка',
        eol: 'Продаден',
        demo: 'Продукт за презентация',
        sale: 'Отстъпка',
      },
      disponibility: {
        primaryLessThan: 'В НАЛИЧНОСТ по-малко от {count} броя ',
        primaryMoreThan: 'В НАЛИЧНОСТ {count, plural, zero {count} other {{count} и повече парчета}}',
        secondaryLessThan: 'В НАЛИЧНОСТ по-малко от {count} бр',
        secondaryMoreThan: 'В НАЛИЧНОСТ {count, plural, zero {count} other {{count} и повече бройки}}',
        external: 'Продуктът е на път за нашия склад',
        unavailable: 'Стоките не са налични',
        showroom: '(изложени в Шоурум)',
        soldout: 'Стоките са разпродадени',
        'on-way': {
          d: '{from, plural, one {Doručíme zítra} two {Doručíme pozítří} few {Доставяме за {from} дни} other {Доставяме за {from} дни}}',
          m: 'Доставяме за {from, plural, one {minutu} two {minuty} other {minut}}',
          M: 'Доставяме за {from, plural, one {měsíc} two {měsíce} few {měsíce} other {měsíců}}',
          dRange: 'Na ceste za {to, plural, few {{from}-{to} dny} other {{from}-{to} dni}}',
          mRange: 'Na ceste za {from}-{to, plural, one {den} few {dny} other {dní}}',
          MRange: 'Na ceste za {from}-{to, plural, one {měsíc} two {měsíce} few {měsíce} other {měsíců}}',
          date: '{datum}',
        },
        time: 'Na ceste za {count, plural, zero {jeden kus} other {{count} a více kusů}}',
      },
    },
    comparePage: {
      title: 'Сравнете',
      removeButton: 'Отстранете продукта',
      nothing: 'Няма продукти за сравнение',
    },
    filter: {
      reset: 'Нулиране на филтъра',
      search: 'Търсене',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Условия на поръчката',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Добави в кошницата',
      },
    },
    invoiceBlogPreview: {
      title: 'Моите фактури',
      button: 'Всички фактури',
    },
    userProfilePage: {
      logout: 'Излез от профила си',
      setting: 'Настройки',
      passwordChange: 'Промяна на паролата',
    },
    appBarActionRegion: {
      changeRegion: 'Смени езика',
      label: 'Завърши',
    },
    subCategories: {
      title: 'Подкатегории',
    },
    aboutShoppingPage: {
      title: 'ВСИЧКО ЗА ПОКУПКАТА',
      contact: 'КОНТАКТИ',
      electronicClosureOfRevenue: '##ЕЛЕКТРОННА РАЗПИСКА',
      informationDuty: 'ЗАДЪЛЖИТЕЛНА ИНФОРМАЦИЯ',
      processingOfPersonalData: 'ОБРАБОТВАНЕ НА ЛИЧНИ ДАННИ',
      cashback: 'CASHBACK',
      delivery: 'ДОСТАВКА',
      availability: 'НАЛИЧНОСТ',
      methodOfPayment: '##МЕТОД НА ПЛАЩАНЕ',
      termsAndConditions: 'УСЛОВИЯ',
      termsOfAppeal: 'УСЛОВИЯ НА РЕКЛАМАЦИЯ',
      contractwWithdrawal: 'ОТТЕГЛЯНЕ ОТ ДОГОВОР',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Данъчният номер е проверен, цените са начислени без ДДС.',
        viesNotVerified: 'Vвашият данъчен номер не е потвърден или не отговаряте на условията за покупка без ДДС. ',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'ИНФОРМАЦИЯ ЗА ДОСТАВКА',
      pleaseComplete: 'Моля, попълнете',
      closeButton: 'Завърши',
    },
    cartPromoCodes: {
      title: 'ПРИЛОЖИ ПРОМО КОД',
      textFieldPlaceholder: 'ПРОМО КОД',
      textFieldHelperText: 'Може да се приложи само един промо код за поръчка',
      applyPromoCodeButton: 'ПРИЛОЖИ',
    },
    cookiesBar: {
      agreeBtn: 'Съгласен съм',
      title: '',
      text: 'Сайтът digiexpert.bg  използва бисквитки за възможно най-доброто изживяване на нашите посетители. Продължавайки да използвате сайта, ние вярваме, че сте съгласни с нашата политика за поверителност и потвърждавате, че приемате бисквитките.',
    },
    cartFormLogin: {
      btnSend: 'Изпратете',
      button: 'Влез',
      title: 'Влизам',
      forgottenPasswordBtn: 'Забравена парола?',
      text1: 'Ще изпратим еднократна парола на посочения имейл адрес.',
      text2: 'След като влезете, задайте нова парола.',
      error: 'Невалидно потребителско име или парола.',
    },
    cartSummartSection: {
      title: 'Обобщение на договора',
    },
    appCartSummarySubjectsList: {
      title: 'Продукт',
      text: 'Име/описание',
    },
    newsletterText: {
      text1: 'Имате ли нужда от съвет?',
      text2: 'Можете да ни се обадите всеки работен ден от 9 до 17:30 часа.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(без ДДС)',
      priceLabel: 'Единична цена',
      amount: 'Количество',
      totalPriceLabel: 'Общо',
      totalPrice: 'Общо',
      submitButton: 'Изпратете поръчка',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: 'бр.',
    },
    cartSummaryListItem: {
      freeTitle: '- Безплатно',
      amountTitle: 'бр.',
    },
    sxCartNotes: {
      note: 'Не е посочена дата на доставка',
      addNote: 'Добави бележка',
    },
    productDetail: {
      promoCode: {
        title: 'с промо код:',
        validTo: 'валиден до:',
      },
      productSendLink: {
        copyLinkBtn: 'Копирайте връзката',
        sendLinkByEmail: 'Изпратете линка на имейл',
      },
      productDetailBarInformation: {
        addToFavourites: 'Добави към любими',
        compareProduct: 'Сравнете продукта',
        sendLink: 'Изпратете връзка',
        serviceList: 'Списък на услугите',
        questions: 'Въпроси',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'Купувам за фирма',
        otherDeliveryInfo: 'Адресът за доставка е различен',
        otherDeliveryInfoAlert: 'ДДС платецът не може да използва друг адрес за доставка.',
      },
      companyNameLabel: 'Търговско дружество',
      firstNameLabel: 'Име',
      lastNameLabel: 'Фамилия',
      streetLabel: 'Улицата',
      cityLabel: 'Град',
      zipLabel: 'Пощенски код',
      companyIDLabel: 'Данъчен номер',
      countryLabel: 'Държава',
    },
    cartFormPersonal: {
      label: 'Адрес за доставка',
      firstNameLabel: 'Име',
      lastNameLabel: 'Фамилия',
      streetLabel: 'Улица, номер на сграда',
      pscLabel: 'Пощенски код',
      cityLabel: 'Град',
      countryLabel: 'Държава',
      phoneLabel: 'Телефонен номер',
      emailLabel: 'е-маил адрес',
      passwordLabel: 'Парола',
      controlPasswordLabel: 'Контролна парола',
    },
    cartFormInvoice: {
      labelDesktop: 'Информация за връзка / адрес за фактуриране',
      labelMobile: 'Информация за връзка',
      companyLabel: 'Търговско дружество',
      password: 'Парола',
      confirmPassword: 'Паролата е потвърдена',
      email: 'Електронна поща',
      firstName: 'Име',
      lastName: 'Фамилия',
      countryLabel: 'Държава',
      phone: 'Телефон',
      phoneError: 'Телефонният номер е неправилен',
      streetLabel: 'Улица, номер на сграда',
      streetNumberLabel: 'номера на сграда',
      zipLabel: 'ПК',
      icoLabel: 'IR',
      dicLabel: 'Данъчен код',
      country: 'Държава',
      cityLabel: 'Град',
      registration: 'Искам да отворя сметка',
      registrationNote: 'и може да проследява вашите поръчки, пратки и плащания...',
      button: 'Запазите промените',
      label: 'Адрес на плащане',
    },
    page404: {
      title: 'За съжаление страницата не съществува...',
      subTitle: 'Продължете към началната страница.',
      button: 'Върнете се към началната страница.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Сортиране по:',
      byName: 'Име',
      byPrice: 'Цена',
      onlyStock: 'Само на склад',
      resetFilter: 'Отказ',
      displayCountOfProducts: 'Продукти:',
      search: 'Търсене',
      clear: 'Изтрий',
      ok: 'OK',
    },
    priceRangeSliderField: {
      title: 'Ценови диапазон',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Филтриране по',
    },
    productListMultipleFilterMobile: {
      title: 'FILTRIRATI',
    },
    cartCompany: {
      companyName: 'Търговско дружество',
      regNo: 'ЕИК',
      taxNo: 'ДДС номер',
      taxNoPlaceholder: 'BG0000000000',
      warningMessage: {
        warning: 'Важно.',
        message: 'В случай на доставка до адрес, различен от адреса на фирмата, върху поръчката се начислява ДДС.',
      },
    },
    searchResultsPage: {
      match: 'За {searchText} намерихме {resultCountFormatted} резултати.',
      nomatch: 'Не намерихме никакви резултати за {searchText}.',
      matchingCategories: 'Намерени категории',
      button: 'Върнете се към началната страница.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Избрани {total} продукти',
    },
    cartDelivery: {
      firstName: 'Име',
      lastName: 'Фамилия',
      companyName: 'Търговско дружество',
      streetLabel: 'Улица',
      streetNumberLabel: 'Номер на сграда',
      zipLabel: 'Пощенски код',
      country: 'Държава',
      cityLabel: 'Град',
      street: 'Улица, номер на къщата',
      zip: 'ПК',
      city: 'Град',
    },
    cartNotes: {
      shippingLabel: 'Добавете бележка към превозвача',
      shippingNote: 'Добавете бележка за куриера',
      sellerLabel: 'Добавете бележка към продавача',
      sellerNote: 'Бележка за продавача',
      loginNote: 'Ако вече имате акаунт при нас, моля влезте в профила си.',
    },
    cartCountryShipping: {
      title: 'Държава за доставка',
      label: 'Изборът на държава влияе върху начина на плащане, доставката и датата на доставка',
    },
    cartAddress: {
      bllingAddress: 'Адрес на плащане',
      deliveryAddress: 'Адрес за доставка',
      pickUpPointAddress: 'Адрес на пункта за получаване',
    },
    cartAgreements: {
      customerVerified:
        'Не съм съгласен с изпращането на въпросник, който е част от програмата „Одобрено за клиенти“, която се провежда с цел измерване на удовлетвореността на клиентите, с цел подобряване на нашите услуги.',
    },
    loginDialog: {
      username: 'Потребителско име',
      password: 'Парола',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Въведените от вас пароли не са еднакви',
      invalidPostalCode: 'Посоченият пощенски код не е във валиден формат',
      usernameIsNotFree: 'Потребител с това име вече е регистриран.',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Добавете нова фактура',
        all: 'Всички фактури',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Номер',
            invoiceDate: 'Датата на фактурата',
            dueDate: 'Крайна дата',
            total: 'без ДДС',
            totalWithTax: 'Общо',
            billingAddress: 'Адрес на плащане',
            orderNumber: 'Пореден номер',
            unpaidAmount: 'Неизплатена сума',
          },
          filter: {
            number: 'Номер',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Преглед на фактурата',
          columns: {
            priceUnit: 'цена/брой',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Крайна сума за плащане',
      tax: 'Включено ДДС',
      withoutTax: 'Общо без ДДС',
    },
    compare: {
      addToCompare: 'Продуктът беше добавен в полето за сравнение',
      removeToCompare: 'Продуктът е премахнат от полето за сравнени',
      price: 'Цена',
      ean: 'ean',
    },
    favorite: {
      addToFavorite: 'Продуктът е добавен към любими',
      removeFromFavorite: 'Продуктът е премахнат от любимите',
    },
    registration: {
      important: 'ВАЖНО!',
      note: 'Ще Ви изпратим еднократна парола по имейл. След като влезете, въведете нова парола.',
      gdprAgreement: 'Трябва да се съгласите с обработването на лични данни',
      submitButton: 'Регистрирам',
      fail: 'Регистрацията не бе успешна, моля, опитайте отново по-късно',
      success: 'Успешна регистрация',
      title: 'Регистрация',
      dialog: 'Ако все още нямате акаунт при нас, можете да се регистрирате',
      here: 'тук',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Търсене на продукти',
    },
    appBarActions: {
      about: 'ВСИЧКО ЗА ПОКУПКАТА',
      aboutHref: '/o-pazaruvane',
      compare: 'Сравнете',
    },
    appBarActionUser: {
      title: 'Влизам',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. ВСИЧКИ ПРАВА ЗАПАЗЕНИ',
    },
    appArticlesPage: {
      title: 'Текстови',
    },
    errorPage: {
      title: 'Възникна грешка.',
      button: 'Начална страница',
    },
    cartSummary: {
      withoutTax: 'без ДДС',
    },
    renewPasswordForm: {
      success: 'Нова парола е изпратена на вашия имейл адрес.',
      emailNotFound: 'Имейлът не е намерен!',
      error: 'Възникна грешка',
    },
    cartSubjectListItemQuantityWarning: 'Текущото количество на продукта е {count}',
  },
};

export default MessagesBg;
